import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
interface location {
  hint: string;
  distance: number;
  location: [];
  name: string
}
interface routeTable {
  code: string;
  durations: [[]];
  sources: location[];
  destinations: location[];
}
interface routeLocation {
  0: number;
  1: number;
}
interface waypoint {
  hint: string;
  distance: number
  location: number[];
  name: string;
}
interface leg {
  steps: [];
  weight: number;
  distance: number;
  summary: string;
  duration: number;
}
interface route {
  legs: leg[];
  weight_name: string;
  geometry: string;
  weight: number;
  distance: number;
  duration: number;
}
interface directions {
  code: string;
  waypoints: waypoint[];
  routes: route[];
}
@Injectable({
  providedIn: 'root'
})
export class RouterService {
  apiKey = "pk.eyJ1IjoiamFkYW1zMTYiLCJhIjoiY2tjYWs4MnFqMGtrdTJ4b3lid25mdTEzNiJ9.6_ZgWirbkFvMhj8r41CHRQ"
  constructor(private http: HttpClient) { }

  getDistances(coordinates: string[]) {
    return this.http.get<routeTable>("https://api.mapbox.com/directions-matrix/v1/mapbox/driving/" + coordinates.join(';') + "?access_token=" + this.apiKey).pipe( pluck('durations'))
  }
  getDirections(coordinates: string[], order: number[]) {
    //Change the coordinates into the order given in the order array
    let orderedCoordinates: string[] = []
    for(let i=0; i < order.length; i++) {
      orderedCoordinates[i] = coordinates[order[i]];
    }

    return this.http.get<directions>("https://api.mapbox.com/directions/v5/mapbox/driving/" + orderedCoordinates.join(';') +"?access_token=" + this.apiKey)
  }
}
