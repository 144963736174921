import { Component } from '@angular/core';
import { GeocoderService } from './geocoder.service';
import { BehaviorSubject } from 'rxjs';
import { RouterService } from './router.service';
import { DistanceService } from './distance.service';
import { HereService } from './here.service';

interface location {
  hint: string;
  distance: number;
  location: [];
  name: string
}
interface routeTable {
  code: string;
  durations: [[]];
  sources: location[];
  destinations: location[];
}
interface routeLocation {
  0: number;
  1: number;
}
interface waypoint {
  hint: string;
  distance: number
  location: number[];
  name: string;
}
interface leg {
  steps: [];
  weight: number;
  distance: number;
  summary: string;
  duration: number;
}
interface route {
  legs: leg[];
  weight_name: string;
  geometry: string;
  weight: number;
  distance: number;
  duration: number;
}
interface directions {
  code: string;
  waypoints: waypoint[];
  routes: route[];
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(private geocoder: GeocoderService, private route: RouterService, private optimizer: DistanceService, private here: HereService) {}
  //For tab navigation
  searchSelected = true;

  //Inputs from the coordinate entry
  inputLat: number = 0;
  inputLon: number = 0;
  inputName: string = "";

  //Inputs from the search
  term = "";
  city = "";
  
  internalLocs: number[][] = []
  internalAddress: string[] = []
  distances = [];
  locations = new BehaviorSubject([0,0]);
  geoms = new BehaviorSubject("");
  clearMarkers = new BehaviorSubject(true);
  waypoints: waypoint[];
  routes: route[];
  results = {};
  emptySet = false;
  order = [];
  geocoderLoading = false;
  optimizeLoading = false;
  sampleGeom = "yfgwHxiuyTdMMwDk]vf@cQhcLpEgFKcKyyDA_ve@bN}|@_MuLeSd_@_YpAapAwy@agAvk@kk@uCmg@qe@ai@ceBeq@q~@}K_r@eIs_GxIyu@xo@edBcIuq@|Jm~Akd@kaDc^sn@{JbLMwS";
  
  selectSearch(val: number) {
    if (val==1) {
      this.searchSelected = true;
    }
    else this.searchSelected = false;

  }
  
  search(term: string, city: string) {
    event.preventDefault();
    this.geocoderLoading = true;
    this.here.convertAddress(term, city).subscribe(response => {
      console.log(response);
      if (response.items.length == 0){
        this.emptySet = true;
      }
      else {
        this.emptySet = false;
        this.results = response.items;
      }
      
      this.geocoderLoading = false;
    }, error => {
      this.geocoderLoading = false;
      this.emptySet = true;
    })
  }
  addLocation(lat: number, lon: number, address: string) {
    this.internalLocs.push([lat,lon]);
    this.internalAddress.push(address);
    this.locations.next([lat,lon,this.internalLocs.length]);
  }
  deleteLocation(index: number) {
    this.internalLocs.splice(index,1);
    this.internalAddress.splice(index,1)
    this.clearMarkers.next(true);
    var i = 1
    this.internalLocs.forEach(waypoint => {
      waypoint[2] = i
      this.locations.next(waypoint)
      i = i+1
    })
  }
  makeFirst(index: number) {
    console.log("Before changes");
    console.log(this.internalLocs);
    //Capture the location at index
    let iLocation = this.internalLocs[index];
    let iAddress = this.internalAddress[index];
    //Capture the first location
    let firstLocation = this.internalLocs[0];
    let firstAddress = this.internalAddress[0];
    //Place location at index into first location
    this.internalLocs[0] = iLocation;
    this.internalAddress[0] = iAddress;
    //Place first location at index
    this.internalLocs[index] = firstLocation;
    this.internalAddress[index] = firstAddress;
    console.log("After changes");
    console.log(this.internalLocs);
    //Update the map
    this.clearMarkers.next(true);
    var i = 1
    this.internalLocs.forEach(waypoint => {
      if(waypoint.length < 3) {
        waypoint.push(i);
      }
      else{
        waypoint[2] = i;
      }
      this.locations.next(waypoint)
      i = i+1
    })
  }
  makeLinkString(){
    let result = "origin="
    let i=0;
    this.waypoints.forEach(waypoint => {
      if (i==0){
       result=result + String(waypoint['location'][1]) + "," + String(waypoint['location'][0]);
       //If there are more than two waypoints, add the waypoints variable
       if (this.waypoints.length > 2) {
         result = result+"&waypoints=";
       }
      }
      else if (i < this.waypoints.length-1) {
        result = result + String(waypoint['location'][1]) + "," + String(waypoint['location'][0]);
        //If this is not the second last waypoint, then add a separator
        if(i < this.waypoints.length - 2){
          result = result + "|";
        }
      }
      else {
        result = result + "&destination=" + String(waypoint['location'][1]) + "," + String(waypoint['location'][0]);
      }
      i=i+1
    });
    return result
  }
  optimize() {
    let optimizeLocs: string[] = []
    //Switch internal locs lat lon to lon lat and make it strings
    console.log("Coming up next are the waypoints being sent to the route finder.")
    this.internalLocs.forEach(waypoint => {
      if(waypoint.length >= 2) {
        console.log(waypoint)
        optimizeLocs.push(String(waypoint[1]) + ',' + String(waypoint[0]))
      }

    })
    this.optimizeLoading = true;
    //Get the distance matrix from OSRM
    this.route.getDistances(optimizeLocs).subscribe(response => {
      console.log(response);
      this.distances = response;
      //Send the distance matrix to the google or tools flask app
      this.optimizer.getBestRoute(this.distances).subscribe(resp => {
        console.log(resp);
        this.order = resp;
        //Get directions for the waypoints in the correct order
        this.route.getDirections(optimizeLocs, this.order).subscribe( res => {
          console.log("Here are the directions")
          console.log(res)
          this.waypoints = res["waypoints"];
          this.routes = res["routes"];
          this.clearMarkers.next(true);
          var i = 1
          this.waypoints.forEach(waypoint => {
            this.locations.next([waypoint['location'][1],waypoint['location'][0] ,i])
            i = i + 1
          });
          this.optimizeLoading = false;
          res["routes"].forEach(element => {
            console.log("Sending shape")
            this.geoms.next(element.geometry);
          });
        });
      })
    });
  }
  ngOnInit() {
    //This is to test the sample geoms
    //this.geoms.next(this.sampleGeom);
  }

}
