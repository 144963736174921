


<div class="container-fluid">
    <div class="row">
        <div class="col-lg">
            <h2>Route My Route</h2>
        
            <p class="lead">Enter addresses below. Add them to the map. Click the optimize button.
                 The stops you added to the map will be re-ordered to take up the least amount of distance, if you are returning to the first location.
                
                </p>
            <br>
        </div>
    </div>
    <div class="row address-area">
        <div class="col-sm">
            <nav>
                <ul class="nav nav-tabs" >
                    <li role="presentation" class="nav-item" ><a class="nav-link" [ngClass]="{'active':  searchSelected }"   href="#" (click)="selectSearch(1)">Address</a></li>
                    <li role="presentation" class="nav-item" ><a  [ngClass]="{'active':  !searchSelected}" class="nav-link" href="#" (click)="selectSearch(0)">Coordinates</a></li>
                  </ul>
            </nav>
            <br>
            <ng-container *ngIf="!searchSelected">
                <form (ngSubmit)="addLocation(inputLat, inputLon, inputName)" #locationForm="ngForm">
                    <div class="form-group row">
                        <label for="latitude" class="col-sm-2 col-form-label">Latitude</label>
                        <div class="col-sm-10">
                            <input class="form-control" id="latitude" type="text" [(ngModel)]="inputLat" name="lat" required min="-90" max="90" #latControl="ngModel">
                            <ng-container *ngIf="latControl.invalid && latControl.touched">
                                <div class="alert alert-danger" *ngIf="latControl.errors.required">
                                    Latitude is required
                                </div>
                                <div class="alert alert-danger" *ngIf="latControl.errors.min || latControl.errors.max">
                                    Latitude is out of range
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="longitude" class="col-sm-2 col-form-label">Longitude</label>
                        <div class="col-sm-10">
                            <input class="form-control" id="longitude" type="text" [(ngModel)]="inputLon" name="lon" min="-180" max="180" #lonControl="ngModel">
                            <ng-container *ngIf="lonControl.invalid && lonControl.touched">
                                <div class="alert alert-danger" *ngIf="lonControl.errors.required">
                                    Longitude is required
                                </div>
                                <div class="alert alert-danger" *ngIf="lonControl.errors.min || lonControl.errors.max">
                                    Longitude is out of range
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="form-group row">
                    <label for="name" class="col-sm-2 col-form-label">Name</label>
                    <div class="col-sm-10">
                        <input class="form-control" id="name" [(ngModel)]="inputName" name="name" required #nameControl="ngModel">
                        <ng-container *ngIf="nameControl.invalid && nameControl.touched">
                            <div class="alert alert-danger" *ngIf="nameControl.errors.required">
                                Name is required
                            </div>
                        </ng-container>
                    </div>

                    </div>
                    <button type="submit" [disabled]="locationForm.invalid" class="btn-primary btn">Submit</button>
                </form>     
                <br>    
            </ng-container>

            <ng-container *ngIf="searchSelected">
                <form (ngSubmit)="search(term, city)" #addressForm="ngForm">
                    <div class="form-group row">
                        <label for="address" class="col-sm-2 col-form-label">Address</label>
                        <div class="col-sm-10">
                            <input class="form-control" id="address" type="text" [(ngModel)]="term" name="term" required #addressControl="ngModel">
                            <ng-container *ngIf="addressControl.invalid && addressControl.touched">
                                <div class="alert alert-danger" *ngIf="addressControl.errors.required">
                                    Address is required
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="form-group row">
                    <label for="city" class="col-sm-2 col-form-label">City</label>
                    <div class="col-sm-10">
                        <input class="form-control" id="city" type="text" [(ngModel)]="city" name="city" required #cityControl="ngModel">
                        <ng-container *ngIf="cityControl.invalid && cityControl.touched">
                            <div class="alert alert-danger" *ngIf="cityControl.errors.required">
                                City is required
                            </div>
                        </ng-container>
                    </div>

                   
                    </div>

                    <button type="submit" [disabled]="addressForm.invalid" class="btn-primary btn">Submit</button>
                    <br>
                </form>
                <br>
            </ng-container>
            
            <table class="table" >
                <thead class="thead-dark">
                    <tr><th scope="col" *ngIf="!emptySet">Search Results</th><th></th></tr></thead>
                    <div class="d-flex justify-content-center" *ngIf="geocoderLoading">
                        <div class="spinner-border " role="status" >
                            <span class="sr-only loading-indicator">Loading...</span>
                        </div>
                    </div>
                    
                    <ng-container *ngIf="!emptySet">
                        <tr  *ngFor="let result of results"><td>{{result.address.label}}</td><td><button type="button" class="btn btn-info" (click)="addLocation(result.position.lat,result.position.lng,result.address.label)">Add</button></td></tr>
                    </ng-container>
                    <ng-container *ngIf="emptySet">
                        <tr><td>Sorry no results found.</td><td></td></tr>
                    </ng-container>

            </table>
        </div>
        <div class="col-sm">
            <div class="leaflet-container fill">
                <div id="map" appMap [locations]="locations" [geoms]="geoms" [clearMarkers]="clearMarkers"></div>
            </div>
        </div>
        <div class="col-sm" *ngIf="internalAddress.length > 0">
            <!--Waypoints as added by the user-->
            <table class="table">
                <thead class="thead-dark">
                    <tr><th></th><th scope="col">Selected Locations</th><th></th></tr></thead>
                    <div class="spinner-border text-center" role="status" *ngIf="geocoderLoading">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <ng-container *ngIf="!emptySet">
                        <tr  *ngFor="let result of internalAddress; let ia_index = index"><td>{{ia_index +1}}</td><td>{{result}}</td><td>
                            <button *ngIf="ia_index > 0" type="button" class="btn btn-primary" (click)="makeFirst(ia_index)">Start</button>
                            <button type="button" class="btn btn-danger" (click)="deleteLocation(ia_index)">Delete</button>
                        
                        </td></tr>
                    </ng-container>
                    <ng-container *ngIf="emptySet">
                        <tr><td>Sorry no results found.</td><td></td></tr>
                    </ng-container>

            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-lg ">
            <span class="d-block p-2 bg-dark text-white loading-indicator">
                <div class="mx-auto">
                   <button type="button" class="btn btn-info" (click)="optimize()">Optimize Route</button>
                </div>
            
            </span>
        </div>
    </div>
    
    <div class="row" *ngIf="optimizeLoading">
        <div class="col-lg">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-center" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="order.length > 0">
        <div class="col-lg">

            <ul class="list-group">
                <li *ngFor="let each of order;let i = index" class="list-group-item">{{internalAddress[each]}} <p *ngIf="i>0">{{routes[0].legs[i-1].distance | kilometers}}km</p></li>
            </ul>
           
        </div>
         
    </div>
    <div class="row" *ngIf="order.length > 0">
        <div class="col-sm">
            <h4>Total Distance</h4><p>{{routes[0].distance | kilometers}}km</p>
        </div>
        <div class="col-sm">
           <a class="btn btn-primary" role="btn" href="https://www.google.com/maps/dir/?api=1&{{makeLinkString()}}&travelmode=driving">Google Directions</a>
        </div>
    </div>


 </div>


