import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

interface HereAddress {
  label: string;
  countryCode: string;
  countryName: string;
  state: string;
  county: string;
  city: string;
  district: string;
  street: string;
  postalCode: string;
  houseNumber: string;
}

interface HereDetails {
  title: string;
  id: string;
  resultType: string;
  houseNumberType: string;
  address: HereAddress
}

interface HereLocation {
  lat: number;
  lng: number;
}

interface HereItem {
  access: HereLocation[];
  address: HereAddress;
  categories: any[];
  id: string;
  position: HereLocation;
  resultType: string;
  scoring: any;
  title: string;
}

interface HereResult {
  items: HereItem[];
}
@Injectable({
  providedIn: 'root'
})
export class HereService {
  //apiCode = 'K_08AJR9VK6-J0LYnFQ415Z6xWVe5AcCCJ1B-IAFFbw'
  apiCode = '4OxNRNKOPEaHiE6FYTLT_jfXkf50ROCO72v7XSQk1dU'
  rootUrl = 'https://geocode.search.hereapi.com/v1/geocode'
  constructor(private http: HttpClient) { }

  convertAddress(address: string, city: string) {
    address = encodeURI(address)
    city = encodeURI(city)
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.apiCode}`)
    }
    return this.http.get<HereResult>(this.rootUrl +"?q="+ address + "&qq=city=" + city+ "&apiKey="+this.apiCode)
  }
}
