import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface BoundingBox {
  0: string;
  1: string;
  2: string;
  3: string;
}
interface Location {
  place_id: number;
  location: string;
  osm_type: string;
  osm_id: number;
  boundingbox: BoundingBox;
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number
}
@Injectable({
  providedIn: 'root'
})

export class GeocoderService {

  constructor(private http: HttpClient) { }

  convertAddress(address: string, city: string) {
    address = encodeURI(address)
    return this.http.get<Location[]>("https://nominatim.openstreetmap.org/search?street=" + address + "&city=" + city+ "&country=Canada&state=AB&format=json")
  }
}
