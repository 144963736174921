import { Directive, ElementRef, Input } from '@angular/core';
import { Map } from 'leaflet';
import * as L from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import 'polyline-encoded';

@Directive({
  selector: '[appMap]'
})
export class MapDirective {
  @Input() locations: BehaviorSubject<string[]>;
  @Input() geoms: BehaviorSubject<string>;
  @Input() clearMarkers: BehaviorSubject<Boolean>;

  apiCode = '4OxNRNKOPEaHiE6FYTLT_jfXkf50ROCO72v7XSQk1dU'

  map: Map
  markersLayer = new L.LayerGroup(); // NOTE: Layer is created here!
  constructor(private el: ElementRef) {
    this.map = new Map(this.el.nativeElement).setView([53,-116], 6)
   }
   onPopupOpen(){
    var tempMarker = this;
    console.log("Doing something with the marker")
    // To remove marker on click of delete button in the popup of marker
    /*$(".marker-delete-button:visible").click(function () {
        this.map.removeLayer(tempMarker);
    });*/
   }
   ngOnInit() {
    const hereTileUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=` + this.apiCode + `&ppi=320`;
      L.tileLayer(hereTileUrl, {attribution: 'Here API', maxZoom:18}).addTo(this.map);
      this.locations.subscribe( obs => {
        if(obs[0] != '0' && obs[1] != '0') {
          var numberIcon = L.divIcon({
            className: "awesome-marker",
            iconSize: [35, 45],
            iconAnchor: [17, 42],
            popupAnchor: [1, -32],
            html: obs[2]
                
            });
          let mark = L.marker([obs[0], obs[1]], { icon: numberIcon});
          this.markersLayer.addLayer(mark);
          this.map.flyTo([obs[0],obs[1]], 10)
        }
      });
      this.markersLayer.addTo(this.map)
      this.geoms.subscribe( observ => {
        console.log("Received shape");
        let polyline = L.Polyline.fromEncoded(observ).addTo(this.map);

      });
      this.clearMarkers.subscribe(ob => {
        this.markersLayer.clearLayers();
      })
    }
   ngAfterViewInit() {
     this.map.invalidateSize();
   }
}
