import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kilometers'
})
export class KilometersPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if(!value){
      return null;
    }
    let result = value/1000;
    return result.toFixed(2);
  }

}
