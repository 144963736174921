import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DistanceService {

  constructor(private http: HttpClient) { }
  getBestRoute(coordinates: string[]){
    return this.http.post<string[]>("https://optimize.albertaog.ca/tsp", coordinates)
  }

}
